// 
// _progress.scss
// 

// Progress height small
.progress-sm {
  height: 5px;
}


// Progress height medium
.progress-md {
  height: 8px;
}


// Progress height large
.progress-lg {
  height: 12px;
}

// Progress height Extra large
.progress-xl {
  height: 16px;
}

.animated-progess{
  position: relative;
  .progress-bar {
    position: relative;
    border-radius: 6px;
    animation: animate-positive 2s;
  }
}

@keyframes animate-positive {
  0% {
      width: 0;
  }
}

.custom-progess{
  position: relative;
  .progress-icon{
      position: absolute;
      top: -15px;
      .avatar-title{
        background: var(--#{$prefix}custom-white);
      }
  }
}

// custom-progress

.custom-progress{
  height: 14px;
  padding: 4px;
  border-radius: 30px;
  .progress-bar{
      position: relative;
      border-radius: 30px;
      
      &::before{
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          background-color: $white;
          border-radius: 7px;
          right: 2px;
          top: 50%;
          transform: translateY(-50%);

      }
  }
}