.li-none {
    padding-left: 0;
    list-style: none !important;
}

.cpr {
    cursor: pointer !important;
}

.radio-border {
    border: 1px solid #adadad !important;
    margin-top: 4px !important;

}

.table {
    border-color: rgb(52 55 71 / 24%) !important;

    .form-check-input[type=checkbox] {
        border: 1px solid grey;
    }
}

.form-control::placeholder {
    color: grey !important;
    /* Replace with your desired color code */
}

::ng-deep{
    .form-check-input:checked{
        background-color: #2ab137 !important;
    }
}

::ng-deep{
    .nav-tabs-custom .nav-item .nav-link::after{
        background: #ac0000 !important;
    }
}

::ng-deep{
    .nav-tabs-custom .nav-item .nav-link.active{
        color: #ac0000 !important;
    }
}

::ng-deep{
    .page-content {
        padding: calc(80px + 1.25rem) calc(1.25rem / 2) 60px calc(1.25rem / 2) !important;
    }
}