// x editable


.editable-input{
  .form-control{
    display: inline-block;
  }
}

.editable-buttons{
  margin-left: 7px;
  .editable-cancel{
    margin-left: 7px;
  }
}
